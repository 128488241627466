<template>
  <div>
    <van-nav-bar
        title="商户列表"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div style="margin-bottom: 15px">
      <van-search
          v-model="searchParamValue"
          label="商户名"
          placeholder="请输入商户名关键词"
          @search="onSearch"
          @clear="onSearchClear"
      />
    </div>

    <UserList :searchParam="searchParam" ref="UserList"></UserList>

  </div>
</template>

<script>
import {
  Button,
  Calendar, Cascader, Cell, Field, NavBar, Popup, Search
} from 'vant';
import * as getDataService from "@/api/serverData/getServerData";
import UserList from "@/view/user/list";


export default {

  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cascader.name]: Cascader,
    [Calendar.name]: Calendar,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Search.name]: Search,
    UserList,
    [NavBar.name]: NavBar,
  },

  data() {
    return {
      searchParamValue: '',
      searchParam: {},
      username: '',
      address: '',
      orderInfoPopupShow: false,
      text: '',
      date: '',
      showCalendar: false,
      show: false,
      cascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },
    };
  },
  created() {
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSearchClear() {
      this.searchParam.username = null;
      this.$refs.UserList.onLoad();
    },
    onSearch() {
      this.searchParam.username = this.searchParamValue;
      this.$refs.UserList.onLoad();
    },
  }
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
