<template>
  <div>
    <van-index-bar :index-list="indexList" v-on:select="indexSelect" v-on:change="changeIndex">
      <div v-for="item in dataList" :key="item.index" :name="item.index">
        <van-index-anchor :index="item.index">{{ item.text }}</van-index-anchor>
        <div v-for="subItem in item.subItem" :key="subItem.id" :subItem="item.id">
          <van-contact-card
              type="edit"
              :name="subItem.username"
              :tel="subItem.phone"
              @click="onEdit(subItem.id)"
          />
        </div>
      </div>
    </van-index-bar>
  </div>
</template>

<script>
import {
  Icon,
  Button,
  ContactCard,
  IndexBar, IndexAnchor
}
  from 'vant';
import * as userAdminService from "@/api/admin/user";


export default {
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [ContactCard.name]: ContactCard,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
  },
  name: "UserList",
  props: ["searchParam"],
  data() {
    return {
      dataList: [],
      indexList: [],
      indexList1: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  created() {
    console.log(this.searchParam)
    userAdminService.getAccountUserKey(this.searchParam).then(res => {
      if (res.status === 200) {
        this.indexList = res.data
      }
    })
    userAdminService.getAccountUserKeyList(this.searchParam).then(res => {
      if (res.status === 200) {
        console.log(res.data)
        console.log(this.dataList)
        this.dataList = res.data
      }
    })
  },
  methods: {
    changeIndex(index) {
      const getAbsentValues = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
          return !arr2.find(obj => {
            return el.index === obj.index;
          });
        });
        return res;
      };

      console.log(index)
      this.searchParam.nameKey = index
      console.log(this.searchParam)
      userAdminService.getAccountUserKeyList(this.searchParam).then(res => {
        if (res.status === 200) {
          console.log("res.data")
          console.log(res.data)
          console.log("getAbsentValues")
          console.log(getAbsentValues(res.data, this.dataList));
          console.log("this.dataList")
          console.log(this.dataList)
          let absentValues = getAbsentValues(res.data, this.dataList)
          if (absentValues.length > 0) {
            absentValues.forEach(i => this.dataList.push(i))
            this.dataList.sort((a, b) => {

              return a.index > b.index ? 1 : -1;

            })
          }
          console.log("this.dataListend")
          console.log(this.dataList)
        }
      })
    },
    indexSelect(index) {
      console.log(index)
    },
    onLoad() {
      userAdminService.getAccountUserKey(this.searchParam).then(res => {
        if (res.status === 200) {
          this.indexList = res.data
        }
      })
      userAdminService.getAccountUserKeyList(this.searchParam).then(res => {
        if (res.status === 200) {
          console.log(res.data)
          console.log(this.dataList)
          this.dataList = res.data
        }
      })
    },
    onEdit(userId) {
      this.$router.push({path: '/user/particulars/' + userId})
    },
  },
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
